import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import requestApi from "../config/request";
import Account from "../components/Account";
import Header from "../components/Header";

const DashboardPage = () => {
  const navigate = useNavigate();
  const [domain, setDomain] = React.useState(null);
  const [appPackage, setAppPackage] = React.useState(null);
  const [user, setUser] = React.useState(null);

  useEffect(() => {
    const user_info = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    if (!user_info) {
      navigate("/");
    } else {
      setUser(user_info)

      requestApi().get("/user").then((res) => {
        if (!res.data.data["user"]) {
          localStorage.removeItem("user");
          navigate("/");
        } else {
          localStorage.setItem("user", JSON.stringify(res.data.data["user"]));
          setUser(res.data.data["user"]);
          if (res.data.data["user"]) {
            if (res.data.data["user"]["domain"]) {
              setDomain(res.data.data["user"]["domain"]);
            }
            if (res.data.data["user"]["appPackage"]) {
              setAppPackage(res.data.data["user"]["appPackage"]);
            }
          }
        }
      }).catch((err) => {
        console.log(err);
        localStorage.removeItem("user");
        navigate("/");
      });
    }
  }, []);

  const [loadingUpgrade, setLoadingUpgrade] = React.useState(false);

  const upgrade = () => {

    // alert("its under development please subscribe manually . contact us goldpriceupdate24@gmail.com");

    // return 


    setLoadingUpgrade(true);

    requestApi().post("/user/subscription").then((res) => {
      setLoadingUpgrade(false);
      if (res.data.data["status"]) {
        const session = res.data.data["session"];
        const url = session["url"];
        window.location.href = url;
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const logout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  const manageSubs = async () => {

    // alert("its under development please subscribe manually . contact us for more info");

    // return

    setLoadingUpgrade(true);
    try {
      const { data } = await requestApi().post("/subscription/customer-portal-link")
      if (data.status) {
        const url = data.data.session.url;
        window.location.href = url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const copyKey = () => {
    navigator.clipboard.writeText(user?.apiSecretKey);
  };

  const [updateAuthDomainLoading, setUpdateAuthDomainLoading] =
    React.useState(false);
  const [updateAutPackageLoading, setUpdateAutPackageLoading] =
    React.useState(false);

  const updateAuth = async (type) => {
    if (type === "domain") {
      if (!domain || domain === "") {
        return false;
      }

      setUpdateAuthDomainLoading(true);
    } else {
      if (!appPackage || appPackage === "") {
        return false;
      }

      setUpdateAutPackageLoading(true);
    }


    const { data } = await requestApi().post("/user", {
      type: type,
      value: type === "domain" ? domain : appPackage
    })


    if (type === "domain") {
      setUpdateAuthDomainLoading(false);
    } else {
      setUpdateAutPackageLoading(false);
    }

    if (data["status"]) {
      setUser(data.data["user"]);
    }

    // axios
    //   .post(API_URL + "/user", {
    //     id: user.id,
    //     type: type,
    //     value: type === "domain" ? domain : appPackage
    //   })
    //   .then((res) => {

    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  return (
    <div className="">
      <Header/>     
      <main className="">


        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="p-10 ">
            <div className="flex gap-5">
              <h1 className="text-2xl font-bold text-black">
                API Secret Key
              </h1>
              <div>
                <button
                  className="bg-yellow-500 text-white py-2 px-4 rounded "
                  onClick={copyKey}>
                  Copy
                </button>
              </div>
            </div>
            <br />
            <div
              className="rounded-xl px-2 table-responsive bg-gray-300"
              style={{
                maxWidth: '350px',
                overflow: 'auto'
              }}
            >
              <span className="p-1 rounded-md">
                {user?.apiSecretKey}
              </span>
            </div>


            <div className="pt-5">
              <br />
              <h1 className="text-3xl">Authentication</h1>
              <br />

              <div>
                <div className="flex">
                  <input
                    onChange={(e) => {
                      setDomain(e.target.value);
                    }}
                    type="text"
                    className="bg-gray-300 p-1 rounded-md w-full"
                    value={domain}
                    placeholder="your domain ex: https://www.example.com"
                  />
                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded"
                    onClick={() => {
                      updateAuth("domain");
                    }}>
                    {updateAuthDomainLoading ? (
                      <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-gray-900"></div>
                      </div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>

              <br />

              <div>
                <div className="flex">
                  <input
                    onChange={(e) => {
                      setAppPackage(e.target.value);
                    }}

                    type="text"
                    className="bg-gray-300 p-1 rounded-md w-full"
                    value={appPackage}
                    placeholder="app package example: com.example.app"
                  />
                  <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={() => {
                    updateAuth("appPackage");
                  }}>
                    {
                      updateAutPackageLoading ? (
                        <div className="flex justify-center items-center">
                          <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-gray-900"></div>
                        </div>
                      ) : (
                        "Update"
                      )
                    }

                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* subscription package */}

          <div className="p-10 justify-center flex">
            <div className="flex gap-5">
              <div>
                <h1 className="text-2xl font-bold text-black">
                  Subscription Package
                </h1>

                <br />

                <div>
                  <h1 className="text-2xl font-bold text-black bg-slate-200 rounded-md text-center">
                    {user?.subscriptionType} Package
                  </h1>
                  <div className="flex justify-center">
                    <span>
                      Exp.{" "}
                      {moment(user?.exparationDate).format(
                        "MMMM Do YYYY"
                      )}{" "}
                    </span>
                  </div>
                </div>
              </div>

              {/* free package */}

              <div>
                {user?.subscriptionType !== "free" ? (
                  <button
                    className="bg-yellow-500 text-white py-2 px-4 rounded "
                    onClick={manageSubs}>
                    {loadingUpgrade ? "loading..." : "Manage"}
                  </button>
                ) : (
                  <button
                    className="bg-yellow-500 text-white py-2 px-4 rounded "
                    onClick={upgrade}>
                    {loadingUpgrade ? "loading..." : "Upgrade"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DashboardPage;
