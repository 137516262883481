import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import './index.css'
import CancelPage from "./pages/CancelPage";
import DashBoardPage from "./pages/DashBoardPage";

import HomePage from "./pages/HomePage"
import SocketConnection from "./pages/SocketConnection";
import SuccessPage from "./pages/SuccessPage";
import ContactUsPage from "./pages/ContactUsPage"
import DocsPage from "./pages/DocsPage";



const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage/>,
  },
  {
    path: "/dashboard",
    element: <DashBoardPage/>,
  },
  {
    path: "/success",
    element: <SuccessPage/>,
  },
  {
    path: "/docs",
    element: <DocsPage/>,
  },
  {
    path: "/canceled",
    element: <CancelPage/>,
  },
  {
    path: "/socket",
    element: <SocketConnection/>,
  },
  {
    path:"/contact-us",
    element: <ContactUsPage/>
  },
  {
    path: "*",
    element: <div className="flex justify-center items-center flex-col h-screen w-screen">
      <h1>404</h1>
      <Link to="/" className="btn bg-slate-500 rounded-md p-2 text-white">Go to Home</Link>

    </div>,
  }
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);