import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Account = () => {
    const [user, setUser] = useState()

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        setUser(user)
    })

    const navigate = useNavigate()

    const [show, setShow] = useState(false)

    const logout = () => {
        localStorage.removeItem("user");
        window.location.href = "/";
    };
    return (
        <div
            className='relative w-10'
        >
            <img
                onClick={() => {
                    setShow(pre => !pre)
                }}
                src={user?.image} className="w-10 rounded-full cursor-pointer" />
            <div
                style={{
                    top: '50px',
                    right: '0px'
                }}
                className={`z-10 ${!show ? 'hidden' : ''} card shadow-lg  absolute border-gray-400 border divide-y divide-gray-100 rounded-lg backdrop-blur-sm bg-white/30 w-44`}>
                <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                    <div>{user?.displayName}</div>
                    <div className="font-medium truncate">{user?.email}</div>
                </div>
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownInformationButton">
                    <li>
                        <Link to="/" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Home</Link>
                    </li>

                    <li>
                        <Link to="/dashboard" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</Link>
                    </li>
                    <li>
                        <Link to="/docs" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Documentation</Link>
                    </li>
                </ul>
                <div className="py-2"
                    onClick={() => {
                        logout()
                    }}
                >
                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign out</a>
                </div>
            </div>
        </div>
    )
}

export default Account