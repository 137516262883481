import React, { useEffect } from "react";
import Prism from "prismjs";
import CodePreview from "./CodePreview";
import TabLanguage from "./TabLanguage";

export default function TabsRender() {
  const [openTab, setOpenTab] = React.useState(0);
  const [exampleCode, setExampleCode] = React.useState("");
  const [language, setLanguage] = React.useState("javascript");

const ReactJs = `
import {  useGoldPrice } from "gold-price-update";
function App() {
  const { gold , error , isConnected } = useGoldPrice('JUW0FuguFLpsMdNIdveXckJJffwSraF')

  return (
    <div>
      <span> {gold?.bid}</span>
      <span> {gold?.ask}</span>
      <span> {gold?.min}</span>
      <span> {gold?.max}</span>
    </div>
  );
}
export default App;
`;

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  useEffect(() => {

    if (openTab === 0) {
      setExampleCode(ReactJs);
      setLanguage("javascript");
    }
    // else if(openTab === 1){
    //     setExampleCode(FlutterCode);
    //     setLanguage("javascript");
    // }else if(openTab === 2){
    //     setExampleCode(PythonCode);
    //     setLanguage("python");
    // }else if(openTab === 3){
    //     setExampleCode(NodeJs);
    //     setLanguage("javascript");
    // }
  }, [openTab]);




  return (
    <div>
      <div className="flex justify-center w-full">
        <TabLanguage name={"React js"} active={openTab} index={0} setOpenTab={setOpenTab} />
        {/* <TabLanguage name={"Flutter"} active={openTab} index={1} setOpenTab={setOpenTab} /> */}
        {/* <TabLanguage name={"Python"} active={openTab} index={2} setOpenTab={setOpenTab} /> */}
        {/* <TabLanguage name={"Node js"} active={openTab} index={3} setOpenTab={setOpenTab} /> */}
      </div>
      <div className="flex justify-center w-full">
        <div className="max-w-4xl w-full">
          <CodePreview code={exampleCode} language={'javascript'} />
        </div>
      </div>
    </div>
  );
}
