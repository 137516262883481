import React from 'react'
import Account from './Account'
import { useNavigate } from 'react-router-dom'

const Header = () => {

    const navigate = useNavigate()
  return (
    <header className="bg-yellow-600  p-6 flex justify-between items-center sticky top-0 w-full">
        <div>

          <div className="flex justify-center items-center gap-3">
            <img
              src="goldpriceupdate.png"
              className="App-logo rounded-lg cursor-pointer"
              alt="logo"
              width={50}
              onClick={()=>{
                navigate('/')
              }}
            />
            <h1 className="cursor-pointer text-xl font-bold text-white text-center" onClick={()=>{
                navigate('/')
              }}>
              Gold Price Update
            </h1>
          </div>
        </div>
        <div className="flex gap-3">
          <div>
            <Account/>
          </div>
          {/* <button
            className="bg-blue-500 text-white py-1 px-4 rounded"
            onClick={logout}>
            Logout
          </button> */}
        </div>
      </header>
  )
}

export default Header