import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Account from "../components/Account";
import TabsRender from "../components/TabsRender";
import CodePreview from "../components/CodePreview";
import Header from "../components/Header";
import TabLanguage from "../components/TabLanguage";

const DocsPage = () => {

    const ReactJs = `
import {  useGoldPrice } from "gold-price-update";
function App() {
  const { gold , error , isConnected } = useGoldPrice('JUW0FuguFLpsMdNIdveXckJJffwSraF')

  return (
    <div>
      <span> {gold?.bid}</span>
      <span> {gold?.ask}</span>
      <span> {gold?.min}</span>
      <span> {gold?.max}</span>
    </div>
  );
}
export default App;
`;


    return (
        <div className="">
            <Header />
            <main className="">


                <div className="flex justify-center w-full pt-5">
                    <div
                        className={`text-xs font-bold uppercase px-5 py-2 shadow-lg block leading-normal rounded-lg text-center cursor-pointer bg-[#916C00] text-white`}>
                        <span>{"React JS"}</span>
                    </div>
                </div>
                <div className="flex justify-center w-full">
                    <div className="max-w-4xl w-full">
                        <CodePreview code={ReactJs} language={'javascript'} />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default DocsPage;
