import React from 'react'

const CancelPage = () => {


    const goBack = () => {
        window.location = "/"
    }

  return (
    <div className='flex justify-center w-full flex-col items-center h-screen'>
        

        <h1 className="felx justify-center">
        something went wrong please try again later or contact us
        </h1>

       <div>
       <button className="btn bg-red-300 rounded-md p-2" onClick={goBack}>
        go back
        </button>
        

       </div>
    </div>
  )
}

export default CancelPage