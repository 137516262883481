import TabsRender from "../components/TabsRender";
import { auth } from "../config/firebase";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import axios from 'axios'
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../config/request";
import Account from "../components/Account";



const HomePage = () => {
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const [user, setUser] = useState()

  const googleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        console.log({ user });
        const displayName = user.displayName;
        const email = user.email;
        const photoURL = user.photoURL;
        axios.post(API_URL + '/user/auth/google/signup', {
          email,
          displayName,
          picture: photoURL,
        }).then((res) => {
          const response = res.data
          // save to local storage
          const user = response.data.user
          const token = response.data.token
          console.log({ user, token });

          localStorage.setItem('user', JSON.stringify(user))
          localStorage.setItem("accessToken", token)
          navigate("/dashboard");
        }).catch((err) => {
          console.log(err)
        })
      })
      .catch((error) => {
        console.log(error);
      });
  };



  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setUser(user)
    // if (user) {
    //   navigate("/dashboard");
    // }
  }, [])



  return (
    <div className="w-full bg-white flex flex-col justify-center">

      <div className="w-full flex justify-end pt-5 pr-5">

        <Account/>

      </div>

      <div className="flex justify-center items-center">
        <img
          src="goldpriceupdate.png"
          className="App-logo"
          alt="logo"
          width={50}
        />
        <h1 className="text-2xl font-bold text-yellow-600 text-center">
          Gold Price Update
        </h1>
      </div>

      <div className="flex justify-center w-full">
        <p className="text-5xl text-yellow-600 text-center">
          <span className="font-bold">Gold</span> &{" "}
          <span className="font-bold text-silver">Silver</span> Prices Socket.io
          API
        </p>
      </div>

      <div className="w-full flex justify-center">
        <span className="text-gray-400 text-center">
          {" "}
          100% Real-Time & Historical Gold and Silver Spot Prices{" "}
        </span>
      </div>
      {
        !user ? <div className="w-full flex justify-center pt-2 flex-col items-center">
          <div><button
            className="bg-[#916c00] text-white px-5 py-1 rounded-sm border border-slate-900"
            onClick={googleLogin}>
            <div className="flex gap-3 justify-center items-center">
              <span>
                <img src="google_logo.svg" alt="key" width={20} />
              </span>{" "}
              Get API Key Now Or Sign In
            </div>
          </button></div>
          <span className="text-gray-500">7 days free key</span>
        </div> : <div className="flex w-full justify-center items-center pt-2">

          {/* <Link className="bg-[#916c00] text-white px-5 py-1 rounded-sm border border-slate-900"
          to="/dashboard">
         Dashboard
        </Link> */}
        </div>
      }


      <div className="w-full p-5">
        {/* tab view */}

        <TabsRender />
      </div>

{
  !user &&  <div className="w-full flex justify-center">
  <button
    className="bg-[#916c00] text-white px-5 py-1 rounded-sm border border-slate-900"
    onClick={googleLogin}>
    <div className="flex gap-3 justify-center items-center">
      <span>
        <img src="google_logo.svg" alt="key" width={20} />
      </span>{" "}
      Get API Key Now Or Sign In
    </div>
  </button>
</div>
}
      

      {/* footer */}
      <footer className="flex justify-center pt-10 pb-5">
        <span>
          <span className="text-gray-400"> © 2021 Gold Price Update </span>
          <span className="text-gray-400"> | </span>
          <span className="text-gray-400"> Privacy Policy </span>
          <span className="text-gray-400"> | </span>
          <span className="text-gray-400"> Terms of Service </span>
          <span className="text-gray-400"> | </span>
          <span className="text-gray-400"> <a href="/contact-us">Contact Us</a> </span>
        </span>
      </footer>
    </div>
  );
};

export default HomePage;
