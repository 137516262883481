
import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom'


import axios from "axios";
import requestApi, { API_URL } from '../config/request';

const SuccessPage = () => {


  let [searchParams, setSearchParams] = useSearchParams();

  const navigator = useNavigate()
  const update = async (type) => {
    try {
      const { data } = await requestApi().post("/subscription/update",)
  
      if (data["status"]) {
        navigator("/dashboard");
      }
  
    } catch (error) {
      navigator("/canceled");
    }

  }

  useEffect(() => {
    const userId = searchParams.get('userId')


    if (!userId) {
      navigator("/")
    }

    const user = JSON.parse(localStorage.getItem("user"))

    if (user) {


      if (user.id != userId) {
        navigator("/")
      }





      update()

      // axios
      //   .post(API_URL + "/subscription/update", {
      //     id: user.id,
      //   })
      //   .then((res) => {

      //   })
      //   .catch((err) => {
      //     navigator("/canceled");
      //   });

    }

  }, []);




  return (
    <div>SuccessPage</div>
  )
}

export default SuccessPage