import React, { useEffect } from "react";
import Prism from "prismjs";

export default function CodePreview({ code, language }) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  return (
    <div className="Code">
      <h1>Example : </h1>
      <pre style={{
        height: "400px",
      }}>
        <code className={`language-${language}`}>{code}</code>
      </pre>
    </div>
  );
}
