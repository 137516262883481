// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBRD4CQl1Nm8EeYpKfpqjWkS-1BbpG4lgs",
  authDomain: "goldpriceupdate-9b1b4.firebaseapp.com",
  projectId: "goldpriceupdate-9b1b4",
  storageBucket: "goldpriceupdate-9b1b4.appspot.com",
  messagingSenderId: "83992976074",
  appId: "1:83992976074:web:858dbb7a3c91b1f7bcf233",
  measurementId: "G-J9C8JL1K3W"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
const provider= new GoogleAuthProvider()
export const signInWithGoogle= ()=>{  //Call this function to get the user data
    signInWithPopup(auth, provider).then((result)=>{
        console.log(result)
    }).catch((error)=>{
        console.log(error);
    })
}
export default app;

