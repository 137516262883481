import { useNavigate } from "react-router-dom";



const ContactUsPage = () => {

  return (
    <div className="w-full bg-white flex flex-col justify-center pt-24">
      <div className="flex justify-center items-center">
        
        <h1 className="text-2xl font-bold text-yellow-600 text-center">
          Contact us - goldpriceupdate24@gmail.com
        </h1>
      </div>

     


      {/* footer */}
      <footer className="flex justify-center pt-10 pb-5">
        <span>
          <span className="text-gray-400"> © 2021 Gold Price Update </span>
          <span className="text-gray-400"> | </span>
          <span className="text-gray-400"> Privacy Policy </span>
          <span className="text-gray-400"> | </span>
          <span className="text-gray-400"> Terms of Service </span>
          <span className="text-gray-400"> | </span>
          <span className="text-gray-400"> Contact Us </span>
        </span>
      </footer>
    </div>
  );
};

export default ContactUsPage;
